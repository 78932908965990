
import { defineComponent } from "vue";
import { toFormValidator } from "@vee-validate/zod";
import { useField, useForm } from "vee-validate";
import { User } from "@app/shared";
import { UserUpdateSchema } from "../../shared/schemas";
import { updateUser } from "@/firebase";
import { useI18n } from "vue-i18n";
import UserUpdateObject = User.UserUpdateObject;

export default defineComponent({
  name: "UserUpdateForm",
  emits: ["userError", "userSuccess", "dialogClose"],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    userUid: {
      type: String,
    },
    userEmail: {
      type: String,
    },
    userDisplayName: {
      type: String,
    },
    userPassword: { type: String },
    userCustomClaims: {
      type: Object,
    },
    isCurrentUser: {
      type: Boolean,
    },
    userEnabled: { type: Boolean },
  },
  setup(props, context) {
    // Grab emit from context and get a router object.
    const { emit } = context;
    const { t } = useI18n();
    // Zod schema validation.
    const updateValidationSchema = toFormValidator(UserUpdateSchema);

    const formValues = {
      email: props.userEmail ?? "",
      displayName: props.userDisplayName ?? "",
      customClaims: props.userCustomClaims ?? { role: "" },
      enabled: props.userEnabled ?? true,
    };

    const { handleSubmit, errors, isSubmitting } = useForm<UserUpdateObject>({
      validationSchema: updateValidationSchema,
      initialValues: formValues,
    });

    const { value: email } = useField("email");
    const { value: displayName } = useField("displayName");
    const { value: role } = useField("customClaims.role");
    const { value: enabled } = useField("enabled");

    const onSubmit = handleSubmit(async (values: UserUpdateObject) => {
      // If the values are somehow undefined we return.
      if (!values) return;

      try {
        const result = await updateUser({
          user: { uid: props.userUid, ...values },
        });
        const { data } = result;
        if (data.success) {
          emit("userSuccess", t("users.modal.update.success"));
        } else {
          emit("userError", t("users.modal.update.error"));
        }
      } catch (e) {
        console.error(e);
        emit("userError", t("users.modal.update.error"));
      }
    });

    return {
      updateValidationSchema,
      displayName,
      email,
      role,
      errors,
      onSubmit,
      isSubmitting,
      enabled,
    };
  },
});
