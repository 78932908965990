
import { defineComponent } from "vue";
import { toFormValidator } from "@vee-validate/zod";
import { useField, useForm } from "vee-validate";
import { User } from "@app/shared";
import { UserCreationSchema } from "../../shared/schemas";
import { createUser } from "@/firebase";
import { useI18n } from "vue-i18n";
import UserCreationObject = User.UserCreationObject;

export default defineComponent({
  name: "UserCreationForm",
  emits: ["userError", "userSuccess", "dialogClose"],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    // Grab emit from context and get a router object.
    const { emit } = context;

    const { t } = useI18n();

    const formValues = {
      email: "",
      password: "",
      displayName: "",
      customClaims: {
        role: undefined,
      },
      enabled: true,
    };

    // Zod schema validation.
    const createValidationSchema = toFormValidator(UserCreationSchema);

    const { handleSubmit, errors, resetForm, isSubmitting } =
      useForm<UserCreationObject>({
        validationSchema: createValidationSchema,
        initialValues: formValues,
      });

    const { value: email } = useField("email");
    const { value: password } = useField("password");
    const { value: displayName } = useField("displayName");
    const { value: role } = useField("customClaims.role");
    const { value: enabled } = useField("enabled");

    const onSubmit = handleSubmit(async (values: UserCreationObject) => {
      // If the values are somehow undefined we return.
      if (!values) return;

      try {
        resetForm();
        const result = await createUser({ user: values });
        const { data } = result;
        if (data.success) {
          emit("userSuccess", t("users.modal.create.success"));
        } else {
          emit("userError", t("users.modal.create.error"));
        }
      } catch (e) {
        console.error(e);
        emit("userError");
      }
    });

    return {
      createValidationSchema,
      displayName,
      email,
      password,
      role,
      errors,
      onSubmit,
      isSubmitting,
      enabled,
    };
  },
});
