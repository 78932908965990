
import { defineComponent } from "vue";
import { auth, deleteUser } from "@/firebase";
import { ElMessage } from "element-plus";
import { User } from "@app/shared";
import UserCreationForm from "@/components/UserCreationForm.vue";
import UserUpdateForm from "@/components/UserUpdateForm.vue";
import { mapActions, mapGetters } from "vuex";
import { ActionTypes } from "@/store/actions/types";

export default defineComponent({
  name: "Users",
  components: { UserUpdateForm, UserCreationForm },
  data() {
    return {
      loading: false,
      search: "",
      userCreationDialogVisible: false,
      userUpdateDialogVisible: false,
      selectedUser: {},
      editingUser: false,
    };
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    ...mapActions({ actionFetchUsers: ActionTypes.FETCH_USERS }),
    isCurrentUser(uid: string) {
      return auth.currentUser?.uid === uid;
    },
    isInUse(value: User.UserInformation) {
      return value.enabled ? this.$t("general.yes") : this.$t("general.no");
    },
    convertRole(value: User.UserInformation) {
      const conversions: Record<string, string> = {
        admin: this.$t("general.roles.admin"),
        teacher: this.$t("general.roles.teacher"),
      };
      return value.customClaims?.role
        ? conversions[value.customClaims.role]
        : this.$t("general.roles.no_role");
    },
    handleEdit(row: User.UserInformation) {
      this.selectedUser = {
        uid: row.uid,
        displayName: row.displayName,
        email: row.email,
        enabled: row.enabled,
        customClaims: row.customClaims,
      };
      this.editingUser = true;
      this.userUpdateDialogVisible = true;
    },
    async handleUserOperationSuccess(message: string) {
      ElMessage({ type: "success", message });
      this.userCreationDialogVisible = false;
      this.userUpdateDialogVisible = false;
      await this.fetchUsers(true);
      this.clearUserState();
    },
    async handleUserOperationError(message: string) {
      ElMessage({
        type: "error",
        message,
        duration: 7500,
      });
      this.userCreationDialogVisible = false;
      this.userUpdateDialogVisible = false;

      this.clearUserState();
    },
    clearUserState() {
      this.editingUser = false;
      this.selectedUser = {};
    },
    async handleReset(row: User.UserInformation) {
      const { email } = row;
      this.loading = true;
      try {
        await auth.sendPasswordResetEmail(email);
        this.loading = false;
        const successMessage = this.$t("login.reset.success");
        ElMessage({
          type: "success",
          message: successMessage,
        });
      } catch (e) {
        this.loading = false;
        const errorMessage = this.$t("general.messages.error.something");
        ElMessage({
          type: "error",
          message: errorMessage,
        });
      }
    },
    async handleDelete(row: User.UserInformation) {
      const { uid } = row;
      this.loading = true;
      const result = await deleteUser({ uid });
      this.loading = false;
      const { data } = result;
      if (!data.success) {
        ElMessage({
          type: "error",
          message: this.$t("users.messages.error.removing"),
          duration: 7500,
        });
      } else {
        ElMessage({
          type: "success",
          message: this.$t("users.messages.success.removing"),
        });
      }
      await this.fetchUsers(true);
    },
    async fetchUsers(force = false) {
      // Fetches users from firebase cloud function.
      this.loading = true;
      const success = await this.actionFetchUsers(force);
      if (!success) {
        ElMessage({
          type: "error",
          message: this.$t("general.messages.error.something"),
        });
      }
      this.loading = false;
      return;
    },
  },
  computed: {
    ...mapGetters(["getUsers"]),
  },
});
