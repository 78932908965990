import { z } from "zod";

export const UserCreationSchema = z.object({
  email: z
    .string()
    .email("Sähköposti on väärin muotoiltu")
    .nonempty("Sähköposti on pakollinen"),
  displayName: z.string().nonempty("Nimi on pakollinen"),
  password: z
    .string()
    .min(6, "Salasana pitäis olla vähintään 6 merkkiä")
    .nonempty("Salasana on pakollinen"),
  photoUrl: z.string().url().optional(),
  enabled: z.boolean().optional(),
  customClaims: z.object({
    role: z.string(),
  }),
});

export const UserRegisterSchema = z.object({
  uid: z.string().nonempty(),
  name: z.string().nonempty(),
});

export const UserUpdateSchema = z.object({
  email: z
    .string()
    .email("Sähköposti on väärin muotoiltu")
    .nonempty("Sähköposti on pakollinen"),
  displayName: z.string().nonempty("Nimi on pakollinen"),
  photoUrl: z.string().url().optional(),
  enabled: z.boolean().optional(),
  customClaims: z.object({
    role: z.string(),
  }),
});

export const GameSessionStateSchema = z.object({
  gameCode: z.string().min(1),
  alias: z.string().min(1).max(50),
  answers: z.array(z.number().min(0)),
  time: z.number().min(0),
});

export const GameStatsSchema = z.object({
  gameCode: z.string().min(1),
});

export const GameSessionDeletionSchema = z.object({
  gameCode: z.string().min(1),
});

export const LevelDeletionSchema = z.object({
  levelId: z.string().min(1),
});
