import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_switch = _resolveComponent("el-switch")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_component_el_dialog, {
    onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('dialogClose'))),
    title: _ctx.$t('users.modal.create.title'),
    "model-value": _ctx.visible
  }, {
    footer: _withCtx(() => [
      _createVNode("span", _hoisted_1, [
        _createVNode(_component_el_button, {
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('dialogClose')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("general.cancel")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          loading: _ctx.isSubmitting,
          type: "primary",
          onClick: _ctx.onSubmit
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("general.create")), 1)
          ]),
          _: 1
        }, 8, ["loading", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            error: _ctx.errors.displayName,
            label: _ctx.$t('users.table.headers.name'),
            required: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.displayName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.displayName = $event)),
                autocomplete: "off"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["error", "label"]),
          _createVNode(_component_el_form_item, {
            error: _ctx.errors.email,
            label: _ctx.$t('users.table.headers.email'),
            required: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.email = $event)),
                type: "email",
                autocomplete: "off"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["error", "label"]),
          _createVNode(_component_el_form_item, {
            error: _ctx.errors.password,
            label: _ctx.$t('users.table.headers.password'),
            required: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.password = $event)),
                type: "password",
                "show-password": "",
                autocomplete: "off"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["error", "label"]),
          _createVNode(_component_el_form_item, {
            error: _ctx.errors.customClaims?.role,
            label: _ctx.$t('users.table.headers.role'),
            required: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.role,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.role = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_option, {
                    label: _ctx.$t('general.roles.admin'),
                    value: "admin"
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_option, {
                    label: _ctx.$t('general.roles.teacher'),
                    value: "teacher"
                  }, null, 8, ["label"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["error", "label"]),
          _createVNode(_component_el_form_item, {
            error: _ctx.errors.enabled,
            label: _ctx.$t('users.table.headers.in_use'),
            required: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_switch, {
                modelValue: _ctx.enabled,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.enabled = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["error", "label"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "model-value"]))
}